<template>
<div>
  <columns>
    <column>
        <page-heading
          heading="New Support Request"
        />
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <select-input
          classes="is-medium is-rounded"
          required
          :error="$root.ticket_type_id"
          :value="ticket.ticket_type_id"
          @input="updateType"
          :items="ticketTypes"
          value-key="id"
          label-key="title">
          Type
        </select-input>
      </column>
    </columns>
    <columns>
      <column>
        <text-area
          classes="is-medium is-rounded"
          required
          :error="$root.errors.message"
          :value="ticket.message"
          @input="updateMessage">
          Message
        </text-area>
      </column>
    </columns>
    <columns>
      <column>
        <file-input classes="is-medium is-rounded" @select="updateAttachment">Attach a File</file-input>
      </column>
      <column class="has-text-right">
        <submit-button
          class="is-medium is-rounded"
          :working="opening"
          @submit="openTicket">
          Submit Request
          </submit-button>
      </column>
    </columns>
  </form>
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    opening: false
  }),

  beforeCreate() {
    this.$store.dispatch('support/loadTicketTypes')
  },

  methods: {
    ...mapMutations('support', [
      'updateType',
      'updateMessage',
      'updateAttachment'
    ]),
    openTicket() {
      this.opening = true
      this.$store.dispatch('support/openTicket').then(() => {
        this.opening = false
        this.$toast.success('Support request opened successfully')
        this.$store.commit('support/clearTicket')
        this.$router.push({
          name: 'support'
        })
      }).catch(() => {
        this.opening = false
      })
    }
  },

  computed: {
    ...mapGetters('support', [
      'ticket',
      'ticketTypes'
    ])
  }


}
</script>